<template>
  <div class="flex flex-col w-full p-4 min-h-screen">
    <loading />
    <h1
      class="text-gray-800 font-semibold text-2xl"
      v-text="'Ажилтан нэмэх'"
    ></h1>
    <div class="border-b-2 border-gray-300 my-4"></div>
    <div class="flex flex-col w-96" v-on:keyup.enter="clickedFn">
      <input
        v-model="staff.email"
        type="email"
        placeholder="Цахим хаяг"
        class="input"
      />
      <input
        v-model="staff.password"
        type="password"
        placeholder="Нууц үг"
        class="input"
      />
      <input
        v-model="staff.confirmpassword"
        type="password"
        placeholder="Нууц үгээ давтаж оруулна уу"
        class="input"
      />

      <!-- Button component -->

      <br />
      <div class="flex">
        <button @click="clickedFn" class="button">Илгээх</button>
        <span>&nbsp;&nbsp;</span>
        <button
          @click="
            () => {
              this.$router.push({ name: 'login' });
            }
          "
          class="button-passive"
        >
          Буцах
        </button>
      </div>

      <!-- Show form values -->
    </div>
  </div>
</template>

<script>
import { Base64 } from "js-base64";

import md5 from "js-md5";
import axios from "axios";
import Loading from "../assets/Loading.vue";

// import axios from "axios";

export default {
  data() {
    return {
      staff: {
        email: null,
        password: null,
        confirmpassword: null,
      },
    };
  },
  components: {
    Loading,
    // ButtonComponent,
  },
  methods: {
    clickedFn() {
      if (this.staff.password === this.staff.confirmpassword) {
        this.$store.dispatch("Loading", true);
        axios
          .post("createAccount", {
            email: this.staff.email,
            password: Base64.encode(md5(this.staff.password)),
            createddate: new Date()
          })
          .then((resp) => {
            if (resp.data.status === 1) {
              this.$store.dispatch("Loading", false);
              this.$store.dispatch("authModule/signin", {
                email: this.staff.email,
                password: this.staff.password,
              });
              this.staff.email = null;
              this.staff.password = null;
              this.staff.confirmpassword = null;

              console.log(resp);
            } else {
              alert(resp.data.msg);
              this.$store.dispatch("Loading", false);
            }
          })
          .catch((err) => {
            console.log("err:" + err);
            this.$store.dispatch("Loading", false);
          });
      } else {
        alert("Нууц үг зөрж байна.");
      }
    },
  },
};
</script>

<style>
</style>